/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select, {
  ActionMeta,
  GetOptionLabel,
  MenuPlacement,
  mergeStyles,
} from "react-select";
import { toast } from "react-toastify";
import { KEY, TYPE } from "../../utils/Constant";
import { autocompleteStyle, multiValueRemove } from "./StyleComponent";
import TextValidator from "./OCTTextValidator";
import { removeDiacritics } from "../../utils/FunctionUtils";
// import { useMultiLanguage } from "../../hook";
import { flatArrayByChildrenName } from "../../../AppFunction";

export interface IAutoCompleteProps {
  options: any[];
  horizontal?: boolean;
  isClearable?: boolean;
  isYeuCauKham?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  searchFunction?: (searchObject: any) => Promise<any> | null | 0 | undefined;
  searchObject?: any | undefined;
  onChange?:
    | ((newValue: any, actionMeta?: ActionMeta<any>) => void)
    | undefined
    | null;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  className?: string;
  name?: string;
  id?: string;
  key?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode;
  backspaceRemovesValue?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  placeholder?: string;
  setSelectedVal?: any;
  value?: any;
  valueField?: string;
  dependencies?: any[];
  touched?: any;
  errors?: any;
  disabled?: boolean;
  handleClearValue?: any;
  urlData?: string;
  showCode?: boolean;
  displayLable?: string;
  menuPortalTarget?: any;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  defaultValue?: any;
  menuPlacement?: MenuPlacement | undefined;
  styles?: any;
  isReadOnly?: boolean;
  isScroll?: boolean;
  labelSearch?: string;
  lable?: string;
  isRequired?: boolean;
  isAddNew?: boolean;
  onAddNew?: () => void;
  handleAddNew?: () => void;
  valueSearch?: string | number;
  formCheckBox?: boolean;
  isCheckBox?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  setIsCheckBox?: (isCheck: boolean) => void;
  sort?: (data: any) => void;
  customFilterOption?: (option: any, inputValue: any) => void;
  renderChilden?: string;
}

const OCTAutocomplete: FC<IAutoCompleteProps> = (props: IAutoCompleteProps) => {
  const { options, onChange, searchFunction, renderChilden } = props;
  const [optionList, setOptionList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [isCheckDataScroll, setIsCheckDataScroll] = useState<boolean>(false);
  let styles = {
    ...props?.styles,
    ...(props?.isReadOnly ? multiValueRemove : {}),
  };
  const combinedStyles = mergeStyles(autocompleteStyle, styles);
  const [keyword, setKeyword] = useState("");
  // const { lang } = useMultiLanguage()
  const labelRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  useEffect(() => {
    options?.length <= 0 && fetchData();
    ((isFocus && optionList?.length === 0) || (props?.isScroll && !keyword)) &&
      fetchData();
    return () => {
      setIsLoading(false);
    };
  }, [options, searchFunction, isFocus, props.value, keyword]);

  useEffect(() => {
    if (props?.dependencies && props?.dependencies?.length > 0) {
      setOptionList([]);
      setSelectedValue(null);
    }
  }, props?.dependencies || []);

  useEffect(() => {
    getValue();
  }, [props.value, optionList]);

  const convertNameUrl = (value: string, item: any) => {
    const array = value.split(".");
    for (let i = 0; i < array.length; i++) {
      item = item?.[array[i]];
    }
    return item;
  };

  const fetchData = async () => {
    if ((options?.length > 0 && !props.searchFunction) || props.isReadOnly) {
      setOptionList(options);
    } else if (!isLoading) {
      setIsLoading(true);
      try {
        if (props.searchObject !== undefined && props.searchFunction) {
          let data = await getData(props.searchObject);
          setPageIndex(props.searchObject?.pageIndex || 1);
          if (renderChilden) {
            data = flatArrayByChildrenName(data, renderChilden);
          }
          setOptionList(data);
          setIsCheckDataScroll(data?.length > 0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  const getData = async (searchObject: any) => {
    const res = await props.searchFunction?.(searchObject);
    let data = props?.urlData
      ? convertNameUrl(props?.urlData, res)
      : res?.data?.data?.content;
    data = props?.sort ? props?.sort(data) : data || [];
    return data;
  };
  const handleChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
    onChange?.(selectedOption);
  };

  const combinedClassName = clsx(
    props?.className ? props.className : "w-100",
    clsx(
      props.className,
      props.errors && props.touched && "ac-is-invalid",
      "autocomplete-custom radius"
    )
  );

  const getValue = () => {
    if (typeof props?.value === TYPE.OBJECT) {
      setSelectedValue(props?.value);
      return;
    }

    if (
      typeof props?.value === TYPE.STRING ||
      typeof props?.value === TYPE.NUMBER
    ) {
      const value = optionList.find(
        (option: any) =>
          option[props?.valueSearch ? props?.valueSearch : "name"] ===
          (props?.value ?? false)
      );
      setSelectedValue(value);
      return;
    }

    setSelectedValue(null);
  };

  const handleScrollToBottom = async () => {
    try {
      if (isCheckDataScroll) {
        let searchObject = { ...props.searchObject };
        searchObject.pageIndex = pageIndex + 1;
        if (props?.labelSearch) searchObject[props?.labelSearch] = keyword;

        setIsLoading(true);
        let data = await getData(searchObject);
        setPageIndex(pageIndex + 1);
        setIsCheckDataScroll(data?.length > 0);
        if (renderChilden) {
          data = flatArrayByChildrenName(data, renderChilden);
        }
        setOptionList([...optionList, ...data]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLElement>) => {
    if (KEY.SPACE === event.code && !(event.target as HTMLInputElement).value) {
      event.preventDefault();
      return;
    }
  };

  const handleInputChange = (newValue: string) => {
    setKeyword(newValue?.trim());
  };

  const handeBlur = () => {
    setIsFocus(false);
    setKeyword("");
  };

  useEffect(() => {
    searchOption();
  }, [keyword, selectedValue]);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    props?.setIsCheckBox && props?.setIsCheckBox(event?.target?.checked);
  };

  const searchOption = async () => {
    try {
      if (props?.isScroll && !selectedValue) {
        let searchObject = { ...props.searchObject };
        if (props?.labelSearch) searchObject[props?.labelSearch] = keyword;

        setIsLoading(true);
        let data = await getData(searchObject);
        if (renderChilden) {
          data = flatArrayByChildrenName(data, renderChilden);
        }
        setPageIndex(props.searchObject?.pageIndex || 1);
        setOptionList(data);
        setIsCheckDataScroll(data?.length > 0);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };

  const customFilterOption = (option: any, inputValue: any) => {
    const label = String(option.label).toLowerCase();
    const input = String(inputValue).toLowerCase();
    return removeDiacritics(label).includes(removeDiacritics(input));
  };

  useEffect(() => {
    if (props.horizontal && labelRef.current && selectRef.current) {
      labelRef.current.style.marginTop = `calc((${
        selectRef.current?.controlRef?.offsetHeight
      }px - ${getComputedStyle(labelRef.current)?.lineHeight}) / 2)`;
    }
  }, [labelRef.current, selectRef.current]);

  return (
    <div className={`${props.horizontal ? "flex horizontal gap-3" : ""} ${""}`}>
      <span
        className={`
        text-lable-input lable flex-shrink-0 flex-top
        ${props?.formCheckBox ? "flex flex-middle justify-content-between" : ""}
      `}
      >
        <label ref={labelRef} className="lable-autocomplete">
          {props?.lable}
          {props?.isRequired && <span className="color-red"> *</span>}
        </label>
        {props?.formCheckBox && (
          <div className="flex">
            <span>{"(Khác)"}</span>
            <Form.Check
              disabled={props?.isReadOnly}
              className="checkBox check-box-autoComplete"
              name="isCheckBox"
              checked={props?.isCheckBox}
              onChange={handleCheckbox}
            />
          </div>
        )}
      </span>
      <div className="w-100">
        {props?.isCheckBox ? (
          <TextValidator
            name={props?.name || ""}
            value={props?.value || ""}
            onChange={props?.onChange}
            touched={props.touched}
            errors={props.errors}
            readOnly={props?.isReadOnly}
          />
        ) : (
          <Select
            ref={selectRef}
            getOptionLabel={(option: GetOptionLabel<any>) =>
              props.getOptionLabel ? props.getOptionLabel(option) : option.name
            }
            getOptionValue={(option: any) =>
              props.getOptionValue
                ? props.getOptionValue(option)
                : option?.value
            }
            backspaceRemovesValue={props?.backspaceRemovesValue}
            options={optionList}
            noOptionsMessage={({ inputValue }: any) =>
              props?.isAddNew ? (
                <button
                  className="button-primary w-100"
                  onClick={props?.onAddNew}
                >
                  Thêm mới
                </button>
              ) : (
                <span>Không tìm thấy lựa chọn cho {inputValue}</span>
              )
            }
            className={combinedClassName}
            name={props?.name}
            value={selectedValue || props?.value}
            id={props?.id}
            key={props?.key}
            onFocus={() => setIsFocus(true)}
            onBlur={handeBlur}
            isDisabled={props?.isDisabled}
            isLoading={isLoading}
            styles={combinedStyles}
            minMenuHeight={props?.minMenuHeight}
            maxMenuHeight={props?.maxMenuHeight}
            placeholder={
              <p className="color-placeholder spaces fs-13 m-0">
                {/* {props?.placeholder || `Chọn ${props?.lable ? props?.lable?.toLowerCase() : ""}...`} */}
                {props?.placeholder || `Chọn ...`}
              </p>
            }
            onChange={handleChange}
            menuPortalTarget={props?.menuPortalTarget}
            isMulti={props?.isMulti}
            closeMenuOnSelect={props?.closeMenuOnSelect}
            menuPlacement={props?.menuPlacement ? props?.menuPlacement : "auto"}
            onMenuScrollToBottom={
              props?.isScroll ? handleScrollToBottom : undefined
            }
            onKeyDown={handleKeyDown}
            onInputChange={handleInputChange}
            hideSelectedOptions={props?.isReadOnly}
            menuIsOpen={props?.isReadOnly ? false : undefined}
            isSearchable={
              props?.isReadOnly
                ? false
                : props?.isSearchable !== undefined
                ? props?.isSearchable
                : true
            }
            isClearable={
              props?.isReadOnly
                ? false
                : props?.isClearable !== undefined
                ? props?.isClearable
                : true
            }
            filterOption={customFilterOption}
          />
        )}
        {props.touched && props.errors && (
          <div className="invalid-feedback">{props.errors}</div>
        )}
      </div>
    </div>
  );
};
export default OCTAutocomplete;
