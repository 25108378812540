import { FormikProvider, useFormik } from "formik";
import { FC, useCallback, useEffect, useState, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { INIT_SEARCH_OBJECT } from "../../../constant";
import { TYPE } from "../../../utils/Constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import CheckboxField from "../../../component/input-field/CheckboxField";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { getListServiceImpatient } from "../../services/ManagerServices";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
import { convertNumberPrice } from "../../../utils/FunctionUtils";
import { IObject } from "../../../models/models";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { localStorageItem } from "../../../utils/LocalStorage";
import AppContext from "../../../../AppContext";
import { OctTable, columnNamesType, TreeView } from "@oceantech/oceantech-ui";

interface IProps {
  handleCloseDialog: () => void;
  handleSelected: (data: any) => void
  classId?: string;
  listTermId?: string;
  bedServiceListData?: any;
}
const BedServiceDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, handleSelected, classId, listTermId, bedServiceListData } = props;
  const { setPageLoading } = useContext(AppContext);
  const intl = useIntl();
  const { lang } = useMultiLanguage();
  let accessTockenDecode = localStorageItem.get(
    KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE
  );
  const [bedServiceList, setBedServiceList] = useState([]);
  const [searchObject, setSearchObject] = useState<IObject>({
    ...INIT_SEARCH_OBJECT,
    orgId: "",
    level: 1,
  });

  useEffect(() => {
    handleGetBedService();
  }, [searchObject]);

  // useEffect(() => {
  //   handleGetBedService();
  // }, []);

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("BED_SERVICE.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED_SERVICE.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED_SERVICE.UNIT"),
      field: "unit",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_INS"),
      field: "svcFeeIns",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_HOSPITAL"),
      field: "svcFeeHospital",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_REQUEST"),
      field: "svcFeeRequest",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  const handleGetBedService = async () => {
    var id = classId || "";
    try {
      setPageLoading(true);
      const res = await getListServiceImpatient(id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        var list = data[0]?.terms?.map((item: any, index: number) => {
          return {
            ...item,
            stt: index + 1,
            ...item?.attributes,
            svcFeeIns: convertNumberPrice(item?.attributes?.svcFeeIns) || 0,
            svcFeeHospital: convertNumberPrice(item?.attributes?.svcFeeHospital) || 0,
            svcFeeRequest: convertNumberPrice(item?.attributes?.svcFeeRequest) || 0,
          };
        });
        setBedServiceList(list.filter((item: any) => item?.name.includes(searchObject?.keyword) || item?.code.includes(searchObject?.keyword)));
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    let _values = {
      ...values,
      // orgId: accessTockenDecode?.org,
    };
    console.log(_values);
    handleSelected(_values);
    handleCloseDialog();
  };

  const formik = useFormik({
    initialValues: {
      listTermId: listTermId || "",
    },
    onSubmit: handleSubmit,
  });

  // const handleChangeSelect = (name: string, data: any) => {
  //   formik.setFieldValue(name, data);
  // };

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="xl" centered>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i
                  className={`bi " bi-plus-circle" text-primary ps-2 fs-5`}
                ></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="px-6">
                <OctTable
                  title={lang("BED_SERVICE.LIST")}
                  isActionTableTab
                  data={bedServiceList}
                  columns={columns}
                  noToolbar={true}
                  handleOpenDialog={() => {
                  }}
                  setDataChecked={(rows: any) => {
                    formik.setFieldValue("listTermId", rows.map((item: any) => `${item.id}`).join(','));
                  }}
                  dataChecked={bedServiceListData}
                  scrollable={true}
                  notDelete={true}
                  isSearchable
                  height={400}
                  noPagination={true}
                  handleSearch={handleGetBedService}
                  unSelectedAll
                  type={TYPE.MULTILINE}
                  searchObject={searchObject}
                  setSearchObject={setSearchObject}
                  className="custom-list-data"
                />
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={handleCloseDialog}
              >
                {intl.formatMessage({
                  id: "BTN.CANCEL",
                })}
              </Button>
              <Button className="btn btn-primary btn-sm" type="submit">
                {intl.formatMessage({ id: "BTN.SAVE" })}
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
export { BedServiceDialog };
