import { FormikProvider, useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { IChamber } from "../../model/ManagerModel";
import { toast } from "react-toastify";
import {
  addNewChamber,
  updateChamber,
} from "../../services/ManagerServices";
import { SIMPLE_CATEGORY_TYPE, SUCCESS_CODE } from "../../../constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import CheckboxField from "../../../component/input-field/CheckboxField";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { INIT_CHAMBER, LEVEL_DEPARTMENT } from "../const/ManagerCost";
import {
  getSimpleCategory,
  searchDepartment,
} from "../../services/OrganizationServices";
// import { OCTAutocomplete } from "@oceantech/oceantech-ui";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { localStorageItem } from "../../../utils/LocalStorage";
import OCTAutocomplete from "../../../component/input-field/OCTAutocomplete";
import { log } from "console";

interface IProps {
  handleCloseDialog: () => void;
  chamberInfo: IChamber;
  handleReload: () => Promise<void>;
}
const ChamberDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, chamberInfo, handleReload } = props;
  const intl = useIntl();
  const { lang } = useMultiLanguage();
  const [chamberList, setChamberList] = useState<IChamber[]>([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    code: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    deptDepartmentId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    deptRoomId: Yup.string().required(
      intl.formatMessage({ id: "VALIDATION.REQUIRE" })
    ),
    // parentId: Yup.string().required(
    //   intl.formatMessage({ id: "VALIDATION.REQUIRE" })
    // ),
  });
  let accessTockenDecode = localStorageItem.get(
    KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE
  );

  useEffect(() => {
    if (chamberInfo?.id) {
      formik?.setValues(chamberInfo);
    }
  }, [chamberInfo]);

  const handleSubmit = async (values: IChamber) => {
    let _values = {
      ...values,
      // orgId: accessTockenDecode?.org,
    };
    console.log(_values);
    
    try {
      const res = chamberInfo?.id
        ? await updateChamber(_values)
        : await addNewChamber(_values);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(
          chamberInfo.id
            ? intl.formatMessage({ id: "TOAST.EDIT.SUCCESS" })
            : intl.formatMessage({ id: "TOAST.CREATE.SUCCESS" })
        );
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
    }
  };

  const formik = useFormik({
    initialValues: chamberInfo?.id ? chamberInfo : INIT_CHAMBER,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeSelect = (name: string, data: any) => {
    formik.setFieldValue(name, data);
  };

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({
                  id: !chamberInfo?.id
                    ? "CHAMBER.ADD"
                    : "CHAMBER.EDIT",
                })}
                <i
                  className={`bi ${
                    chamberInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                  } text-primary ps-2 fs-5`}
                ></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="px-6">
                <Col xs={6} className={"pt-4"}>
                  <OCTAutocomplete
                    lable={lang("CHAMBER.FACULTY")}
                    options={[]}
                    value={
                      formik?.values?.deptDepartmentId || chamberInfo?.deptDepartmentId || null
                    }
                    name="deptDepartmentId"
                    valueSearch="id"
                    isRequired
                    onChange={(selectedOption: any) => {
                      handleChangeSelect("deptDepartmentId", selectedOption?.id);
                      handleChangeSelect("deptDepartmentName", selectedOption?.name);
                    }}
                    getOptionValue={(option) => option.deptDepartmentId}
                    searchFunction={searchDepartment}
                    searchObject={{
                      pageSize: 999,
                      pageIndex: 1,
                      level: LEVEL_DEPARTMENT.KHOA,
                    }}
                    errors={formik.errors.deptDepartmentId}
                    touched={formik.touched.deptDepartmentId}
                    // renderChilden="subs"
                    // disabled={isView}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <OCTAutocomplete
                    lable={lang("CHAMBER.ROOM")}
                    options={[]}
                    value={
                      formik?.values?.deptRoomId ||
                      chamberInfo?.deptRoomId ||
                      null
                    }
                    name="deptRoomId"
                    valueSearch="id"
                    isRequired
                    onChange={(selectedOption: any) => {
                      handleChangeSelect("deptRoomId", selectedOption?.id);
                      handleChangeSelect("deptRoomName", selectedOption?.name);
                    }}
                    isDisabled={!formik?.values?.deptDepartmentId}
                    getOptionValue={(option) => option.deptRoomId}
                    searchFunction={searchDepartment}
                    searchObject={{
                      pageSize: 999,
                      pageIndex: 1,
                      // type: formik?.values?.type,
                      level: LEVEL_DEPARTMENT.PHONG,
                      parentId: formik?.values?.deptDepartmentId,
                    }}
                    errors={formik.errors.deptRoomId}
                    touched={formik.touched.deptRoomId}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <OCTTextValidator
                    name="code"
                    lable={lang("CHAMBER.CODE")}
                    type={"text"}
                    isRequired
                    onChange={formik.handleChange}
                    value={formik.values.code || ""}
                    errors={formik.errors.code}
                    touched={formik.touched.code}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <OCTTextValidator
                    name="name"
                    lable={lang("CHAMBER.NAME")}
                    type={"text"}
                    isRequired
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    errors={formik.errors.name}
                    touched={formik.touched.name}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <OCTAutocomplete
                    lable={lang("CHAMBER.TYPE")}
                    options={[]}
                    value={
                      formik?.values?.type || chamberInfo?.type
                        ? (
                            formik?.values?.type || chamberInfo?.type
                          )?.toString()
                        : null
                    }
                    name="type"
                    valueSearch="code"
                    onChange={(selectedOption: any) => {
                      handleChangeSelect("type", selectedOption?.code);
                      handleChangeSelect("typeName", selectedOption?.name);
                    }}
                    getOptionValue={(option) => option.level}
                    searchFunction={getSimpleCategory}
                    searchObject={{
                      pageSize: 999,
                      pageIndex: 1,
                      type: SIMPLE_CATEGORY_TYPE.CHAMBER_TYPE,
                    }}
                    errors={formik.errors.type}
                    touched={formik.touched.type}
                    // renderChilden="subs"
                    // disabled={isView}
                  />
                </Col>
                <Col xs={3} className={"pt-4"}>
                  <OCTTextValidator
                    name="sortWeight"
                    lable={lang("CHAMBER.SORT_WEIGHT")}
                    type={"number"}
                    onChange={formik.handleChange}
                    value={formik.values.sortWeight || 0}
                    errors={formik.errors.sortWeight}
                    touched={formik.touched.sortWeight}
                  />
                </Col>
                <Col xs={12} className={"pt-4"}>
                  <OCTTextValidator
                    name="remark"
                    lable={lang("CHAMBER.REMARK")}
                    type={"text"}
                    as={"textarea"}
                    onChange={formik.handleChange}
                    value={formik.values.remark || ""}
                    errors={formik.errors.remark}
                    touched={formik.touched.remark}
                  />
                </Col>
                <Col xs={2} className={"pt-4"}>
                  <CheckboxField
                    name="disabled"
                    label={lang("CHAMBER.DISABLED")}
                    handleChange={formik.handleChange}
                    value={formik.values.disabled || false}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={handleCloseDialog}
              >
                {intl.formatMessage({
                  id: "BTN.CANCEL",
                })}
              </Button>
              <Button className="btn btn-primary btn-sm" type="submit">
                {intl.formatMessage({ id: "BTN.SAVE" })}
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
export { ChamberDialog };
