import { IconMenu } from "../../../component/IconSvg";
import {
  EmployeeProfile,
  IAuth,
  IRole,
  IUser,
  IuserByUnit,
} from "../../model/ManagerModel";

export const INIT_USER: IuserByUnit = {
  id: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    roles: [],
  } as IUser,
  employee: {} as EmployeeProfile,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: "",
};

export const INIT_AUTH: IAuth = {
  id: "",
  orgId: "",
  name: "",
  code: "",
};

export const INIT_ROLE: IRole = {
  id: "",
  orgId: "",
  name: "",
  description: "",
  authorities: [],
};

export const findChildrenNamesByValue = (array: any[], value: string) => {
  let result: any[] = [];

  array.forEach((item) => {
    if (item.value.startsWith(value)) {
      if (item.children.length === 0) {
        result.push(item.value);
      } else {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    } else {
      if (item.children.length > 0) {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    }
  });
  return result;
};

export const INIT_CHAMBER = {
  name: "",
  code: "",
  deptDepartmentId: "",
  deptRoomId: "",
  type: "",
  typeName: "",
  disabled: false,
  remark: "",
  sortWeight: 0,
};

export const INIT_BED = {
  name: "",
  code: "",
  codeIns: "",
  number: 0,
  deptDepartmentId: "",
  deptRoomId: "",
  deptChamberId: "",
  type: "",
  typeName: "",
  termId: 0,
  listTermId: "",
  isOutpatient: false,
  disabled: false,
  remark: "",
  numberOfPatient: 1,
  sortWeight: 0,
};

export const INIT_DEPARTMENT = {
  name: "",
  code: "",
  orgId: "",
  type: null,
  level: null,
  organization: null,
  parentId: "",
};

export const LEVEL_DEPARTMENT = {
  KHOA: 1,
  PHONG: 2,
  BUONG: 3,
  GUIONG: 4,
}

export const LOAI_PHONG = {
  PHONG_KHAM_BENH: 1,
  PHONG_XET_NGHIEM: 2,
  PHONG_CDHA: 5,
  LAY_MAU: 6,
};

export const TreeChiDinhDichVu = {
  code:"all",
  name:"Tất cả dịch vụ",
  icon:<IconMenu/>,
  filter: [],
};

export const CODE_DOI_TUONG = {
  BAO_HIEM: "BHYT",
  VIEN_PHI: "VP",
  MIEN_PHI: "MP",
  YEU_CAU: "YC",
}