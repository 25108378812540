import { FC, useContext, useEffect, useState } from "react";
import { IBed, IDepartment } from "../model/ManagerModel";
import { Row as RowTable } from "react-table";
import { toast } from "react-toastify";
import {
  deleteBed,
  getListBed,
} from "../services/ManagerServices";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import {
  RESPONSE_STATUS_CODE,
  SEARCH_OBJECT_MAX_SIZE,
  TYPE,
} from "../../utils/Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { BedDialog } from "./Dialog/BedDialog";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../../AppContext";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import { getListServiceTypeImpatient } from "../services/ManagerServices";
import { Org } from "../model/UserModels";
import { OctTable, columnNamesType, TreeView } from "@oceantech/oceantech-ui";

const BedList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [bedList, setBedList] = useState<IBed[]>([]);
  const [bedInfo, setBedInfo] = useState<IBed>(
    {} as IBed
  );
  const [bedServiceClassId, setBedServiceClassId] = useState<any>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [openBedDialog, setOpenBedDialog] =
    useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>({
    ...INIT_SEARCH_OBJECT,
    orgId: "",
    level: 1,
  });

  const [orgId, setOrgId] = useState<string>();

  useEffect(() => {
    loadListBed();
  }, [searchObject]);

  useEffect(() => {
    loadListBed();
    getListServiceType();
  }, []);

  const handleOpenBedDialog = (data: RowTable<IBed>) => {
    setOpenBedDialog(true);
    setBedInfo(data);
  };

  const handleCloseBedDialog = (): void => {
    setOpenBedDialog(false);
    setBedInfo({} as IBed);
  };

  const loadListBed = async () => {
    try {
      setPageLoading(true);
      const res = await getListBed(searchObject);
      const { data } = res?.data || {};
      let pageSize = searchObject?.pageSize;
      let totalElements = data?.pageable?.totalElements || 0;
      let totalPages =
        totalElements % pageSize === 0
          ? totalElements / pageSize
          : Math.floor(totalElements / pageSize) + 1;
      setBedList(data?.content);
      setTotalPage(totalPages);
      setTotalElements(totalElements);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const getListServiceType = async () => {
      try {
        setPageLoading(true);
        const res = await getListServiceTypeImpatient('bedService');
        const { code, data } = res.data;
        if (isSuccessfulResponse(code)) {
          setBedServiceClassId(data?.content[0]?.id);
        } else {
          handleThrowResponseMessage(res);
        }
      } catch (err) {
        toast.error(lang("GENERAL.ERROR"));
        setPageLoading(false);
      } finally {
        setPageLoading(false);
      }
    };

  const handleDeleteChamber = async (ids: string[]) => {
    try {
      const res = await deleteBed(ids.toString());
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListBed();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("BED.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.CODE_INS"),
      field: "code",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.TYPE"),
      field: "typeName",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.FACULTY"),
      field: "deptDepartmentName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.ROOM"),
      field: "deptRoomName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.CHAMBER"),
      field: "deptChamberName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED.NUMBER_OF_PATIENT"),
      field: "numberOfPatient",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("CHAMBER.REMARK"),
      field: "remark",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.SORT_WEIGHT"),
      field: "sortWeight",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  return (
    <div>
        <OctTable
          title={lang("BED.LIST")}
          isActionTableTab
          data={bedList}
          columns={columns}
          handleDeleteList={(ids: string[]) => handleDeleteChamber(ids)}
          noToolbar={true}
          buttonAdd
          handleOpenDialog={() => {
            setOpenBedDialog(true);
          }}
          handleDoubleClick={(row: any) => handleOpenBedDialog(row)}
          isSearchable
          handleSearch={loadListBed}
          totalElements={totalElements}
          totalPages={totalPage}
          unSelectedAll
          type={TYPE.MULTILINE}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          className="custom-list-data"
        />
      {openBedDialog && (
        <BedDialog
          handleCloseDialog={handleCloseBedDialog}
          bedInfo={bedInfo}
          bedServiceClassId={bedServiceClassId}
          handleReload={loadListBed}
        />
      )}
    </div>
  );
};
export { BedList };
