import { FC, useContext, useEffect, useState } from "react";
import { IChamber, IDepartment } from "../model/ManagerModel";
import { Row as RowTable } from "react-table";
import { toast } from "react-toastify";
import {
  deleteChamber,
  getListChamber,
} from "../services/ManagerServices";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import {
  RESPONSE_STATUS_CODE,
  SEARCH_OBJECT_MAX_SIZE,
  TYPE,
} from "../../utils/Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { ChamberDialog } from "./Dialog/ChamberDialog";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../../AppContext";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import { getListOrganizations } from "../services/OrganizationServices";
import { Org } from "../model/UserModels";
import { OctTable, columnNamesType, TreeView } from "@oceantech/oceantech-ui";

const ChamberList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [chamberList, setChamberList] = useState<IChamber[]>([]);
  const [chamberInfo, setChamberInfo] = useState<IChamber>(
    {} as IChamber
  );
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [openChamberDialog, setOpenChamberDialog] =
    useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>({
    ...INIT_SEARCH_OBJECT,
    orgId: "",
    level: 1,
  });

  const [orgId, setOrgId] = useState<string>();

  useEffect(() => {
    loadListChamber();
  }, [searchObject]);

  useEffect(() => {
    loadListChamber();
  }, []);

  const handleOpenDepartmentDialog = (data: RowTable<IChamber>) => {
    setOpenChamberDialog(true);
    setChamberInfo(data);
  };

  const handleCloseDepartmentDialog = (): void => {
    setOpenChamberDialog(false);
    setChamberInfo({} as IChamber);
  };

  const loadListChamber = async () => {
    try {
      setPageLoading(true);
      const res = await getListChamber(searchObject);
      const { data } = res?.data || {};
      let pageSize = searchObject?.pageSize;
      let totalElements = data?.pageable?.totalElements || 0;
      let totalPages =
        totalElements % pageSize === 0
          ? totalElements / pageSize
          : Math.floor(totalElements / pageSize) + 1;
      setChamberList(data?.content);
      setTotalPage(totalPages);
      setTotalElements(totalElements);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteChamber = async (ids: string[]) => {
    try {
      const res = await deleteChamber(ids.toString());
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListChamber();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleNodeClick = (node: any) => {
    let orgId = node?.id;
    setOrgId(undefined);
    if (!orgId) return;
    setOrgId(orgId);
    setSearchObject({
      ...searchObject,
      orgId: orgId,
      ...INIT_SEARCH_OBJECT,
    });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("CHAMBER.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.TYPE"),
      field: "typeName",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.FACULTY"),
      field: "deptDepartmentName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.ROOM"),
      field: "deptRoomName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.REMARK"),
      field: "remark",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("CHAMBER.SORT_WEIGHT"),
      field: "sortWeight",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  return (
    <div>
        <OctTable
          title={lang("CHAMBER.LIST")}
          isActionTableTab
          data={chamberList}
          columns={columns}
          handleDeleteList={(ids: string[]) => handleDeleteChamber(ids)}
          noToolbar={true}
          buttonAdd
          handleOpenDialog={() => {
            setOpenChamberDialog(true);
          }}
          handleDoubleClick={(row: any) => handleOpenDepartmentDialog(row)}
          isSearchable
          handleSearch={loadListChamber}
          totalElements={totalElements}
          totalPages={totalPage}
          unSelectedAll
          type={TYPE.MULTILINE}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          className="custom-list-data"
        />
      {openChamberDialog && (
        <ChamberDialog
          handleCloseDialog={handleCloseDepartmentDialog}
          chamberInfo={chamberInfo}
          handleReload={loadListChamber}
        />
      )}
    </div>
  );
};
export { ChamberList };
