import { useNavigate } from "react-router-dom";
import "./homexpage.scss";
import { useAuth } from "../../modules/auth";
import { localStorageItem } from "../../modules/utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../modules/auth/core/_consts";
import { useEffect, useState } from "react";

export function HomeXPage() {
  const navigate = useNavigate();
  const storedData = localStorageItem.get(KEY_LOCALSTORAGE.ID_TOKEN_DECODE);
  const configuration = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION);
  const mainMenus = storedData?.mainmenus;

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;
  const [totalPages, setTotalPages] = useState(0);

  const categories = storedData?.menu?.categories || [];
  const [activeButton, setActiveButton] = useState(0);
  const [filterName, setFilterName] = useState("Tất cả");
  const listFilter = ["Tất cả" ,...categories];

  const [currentMenus, setCurrentMenus] = useState([]);
  let arrColor=[
    '',
    '0deg, #FF9E1B 0%, #FFCF54 100%',
    '108.38deg, #A8FB3C 0%, #227E22 98.88%', 
    '180deg, #7989D4 0%, #2D74D5 100%',
    '0deg, #B03969 0%, #760C63 100%, #DE6A98 100%',
    '180deg, #FF6A9C 0%, #FE5762 100%',
    '270deg, #015C91 0%, #55A7D9 100%',
    '180deg, #289C8F 0%, #2FC4B1 100%',
    '288.13deg, #0C5F55 0%, #56EADA 100%',
    '288.13deg, #764BA2 0%, #667EEA 100%',
    '288.13deg, #764BA2 0%, #667EEA 100%',
    '108.13deg, #7F92A6 0%, #485563 100%',
    '270deg, #FEB74D 0%, #F37D01 100%'
  ]
  const mainMenuConvert = () => {
    let i = 0;
    let combinedMenuList: any = [];
    Object.values(mainMenus || []).forEach((mainMenu: any) => {
      const updatedMenu = mainMenu.menu.map((item: any, index: any) => {
        i++;
        return ({
          ...item,
          uri:
            process.env.REACT_APP_SSO_CLIENT_ID !== mainMenu.serviceCode &&
            mainMenu.serviceWebClient
              ? mainMenu.serviceWebClient + item.uri
              : item.uri,
          background: arrColor[i]
        })
      });
      combinedMenuList = combinedMenuList.concat(updatedMenu);
    });

    // combinedMenuList.sort((a: any, b: any) => a.order - b.order);
    return combinedMenuList;
  };
  const isGroupByServiceCode = false;

  const getFilteredItems = () => {
    const items = isGroupByServiceCode ? mainMenus : mainMenuConvert();
    if (filterName === "Tất cả") {
      return items.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      );
    }
    return items
      .filter((itemMenu: any) => itemMenu?.category === filterName)
      .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  };

  useEffect(() => {
    const currentMenusUpdate = getFilteredItems();
    setCurrentMenus(currentMenusUpdate);

    const items = isGroupByServiceCode ? mainMenus : mainMenuConvert();
    const filteredItems =
      filterName === "Tất cả"
        ? items
        : items.filter((itemMenu: any) => itemMenu?.category === filterName);
    const totalPagesUpdate = Math.ceil(filteredItems.length / itemsPerPage);
    setTotalPages(totalPagesUpdate);
  }, [currentPage, filterName]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { logout } = useAuth();

  const handleButtonClick = (to: string) => {
    if (to.startsWith("http://") || to.startsWith("https://")) {
      window.open(to, "_blank");
    } else {
      navigate(to);
    }
  };

  const handleFilter = (newFilterName: string) => {
    setFilterName(newFilterName);
    setCurrentPage(0);
  };

  return (
    <div
      className="main"
      style={{
        backgroundImage: `url(${
          configuration?.background || "/media/images/backgroundHomeX.jfif"
        })`,
      }}
    >
      <div className="spaces">
        <img
          src={configuration?.logo || "/media/logos/xMED.png"}
          alt="Logo xMED"
          className="logo-xmed spaces mt-20 ml-30"
        />
      </div>
      <div className="d-flex justify-content-center">
        <p className="app-name text-uppercase text-left text-white fw-normal heading-3 mt-10 spaces fw-700">
          {configuration?.applicationName || "PHẦN MỀM QUẢN LÝ BỆNH VIỆN"}
        </p>
      </div>
      <div className="filter-buttons">
        {listFilter.map((filterName, index) => {
          return (
            <button
              key={index}
              className={activeButton === index ? "active" : ""}
              onClick={() => {
                setActiveButton(index);
                handleFilter(filterName);
              }}
            >
              {filterName}
            </button>
          );
        })}
      </div>
      <div className="container-home-x">
        {isGroupByServiceCode &&
          currentMenus.map((service: any, index: any) => (
            <div key={index}>
              <p className="text-uppercase text-left text-white fw-normal heading-3 mb-5">
                {service.serviceName}
              </p>
              <div key={index + "-btn-group"} className="button-group-home-x">
                {service.menu.map((item: any, itemIndex: any) => (
                  <div className="item-button-home-x" key={itemIndex}>
                    <img
                      src={
                        item?.icon
                          ? `data:image/svg+xml;base64,${item?.icon}`
                          : "media/svg/dashboard/bills.svg"
                      }
                      className="btn btn-light-primary fw-bolder btn-homexpage"
                      onClick={() =>
                        handleButtonClick(
                          process.env.REACT_APP_SSO_CLIENT_ID !==
                            item.serviceCode && item.serviceWebClient
                            ? item.serviceWebClient + item?.uri
                            : item?.uri
                        )
                      }
                      alt={item?.name}
                    ></img>
                    <span className="title-button-home-x">{item?.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        {!isGroupByServiceCode && (
          <div className="button-group-home-x">
            {currentMenus.map((item: any, index: any) => (
              <div className="item-button-home-x">
                <button
                  className="btn btn-light-primary fw-bolder btn-homexpage"
                  onClick={() => handleButtonClick(item?.uri)}
                  style={{ background: `linear-gradient(${item?.background})` }}
                  key={index}
                >
                  <img
                    src={
                      item?.icon
                        ? `data:image/svg+xml;base64,${item?.icon}`
                        : "media/svg/dashboard/bills.svg"
                    }
                    alt={item?.name}
                  ></img>
                </button>
                <span className="title-button-home-x text-uppercase spaces text-center">{item?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="fx fx-gap pagination-dots">
        {Array.from({ length: totalPages }).map((_, index) => (
            <div
              key={index}
              className={`btn-pagination ${currentPage === index ? "active" : ""}`}
              onClick={() => setCurrentPage(index)}
            ></div>
          ))}
      </div>
      <div className="pagination-buttons">
        {currentPage > 0 ? (
          <i
            className="prev-button bi bi-chevron-left"
            onClick={handlePrevPage}
          ></i>
        ) : (
          <div></div>
        )}
        {currentPage < totalPages - 1 ? (
          <i
            className="next-button bi bi-chevron-right"
            onClick={handleNextPage}
          ></i>
        ) : (
          <div></div>
        )}
      </div>
      <div className="spaces position-fixed fixed-bottom-10 width-100 text-white text-center fs-16">
        Powered by OceanTech
      </div>
    </div>
  );
}
