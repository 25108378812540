import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import {PERMISSIONS, PATH_ROUTES, AUTHORITIES, REDIRECT_PATH} from "../Constant";
import { UserList } from "../modules/manager/component/UserList";
import { HomePage } from "../pages/Homepage/HomePage";
import { HomeXPage } from "../pages/HomeXPage/HomeXPage";
import {OrganizationList} from "../modules/manager/component/OrganizationList";
import {RoleList} from "../modules/manager/component/RoleList";
import {AuthorityList} from "../modules/manager/component/AuthorityList";
import { DepartmentList } from "../modules/manager/component/DepartmentList";
import { FacultyList } from "../modules/manager/component/FacultyList";
import { ChamberList } from "../modules/manager/component/ChamberList";
import { BedList } from "../modules/manager/component/BedList";
import {hasAccessAuthority} from "../modules/utils/FunctionUtils";
import {Person} from "../modules/person/Person";
import React from "react";
import ServiceList from "../modules/manager/component/ServiceList";
import MedicalEquipmentList from "../modules/manager/component/MedicalEquipmentList";
import SupplierList from "../modules/manager/component/SupplierList";
import PackageList from "../modules/manager/component/PackageList";
import { localStorageItem } from "../modules/utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../modules/auth/core/_consts";

interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}

const PrivateRoutes = () => {
  const PrivateRoute: React.FC<PrivateRouteProps> = ({auth, ability, component: Component, redirect,}) => {
    return hasAccessAuthority(auth, ability) ? (<Component/>) : (<Navigate to={redirect}/>);
  };

  const linkTo = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["homepage"] || "/home"

  return (
    <Routes>
      <Route index element={<Navigate to={linkTo} />} />
      <Route path="home" element={<HomePage />} />
      <Route path="home-x" element={<HomeXPage />} />
      <Route path="/*" element={<Navigate to={linkTo} />} />
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route
          path={PATH_ROUTES.SYSTEM.USER + "/*"}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={UserList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.ORGANIZATION + "/*"}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.ORGANIZATION.VIEW}
              component={OrganizationList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.DEPARTMENT}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.DEPARTMENT.VIEW}
              component={DepartmentList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.FACULTY}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.FACULTY.VIEW}
              component={FacultyList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.CHAMBER}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.CHAMBER.VIEW}
              component={ChamberList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.BED}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.BED.VIEW}
              component={BedList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.AUTHORITY}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.AUTHORITY.VIEW}
              component={AuthorityList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.ROLE}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.ROLE.VIEW}
              component={RoleList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.PERSON}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.PERSON.VIEW}
              component={Person}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.SERVICE}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={ServiceList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.PACKAGE}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={PackageList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.EQUIPMENT}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={MedicalEquipmentList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.SUPPLIER}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={SupplierList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to={REDIRECT_PATH.NOT_FOUND}/>} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };

