import { FormikProvider, useFormik } from "formik";
import { FC, useCallback, useEffect, useState, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { IBed } from "../../model/ManagerModel";
import { toast } from "react-toastify";
import { addNewBed, updateBed } from "../../services/ManagerServices";
import { SIMPLE_CATEGORY_TYPE, SUCCESS_CODE } from "../../../constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import CheckboxField from "../../../component/input-field/CheckboxField";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { INIT_BED, LEVEL_DEPARTMENT } from "../const/ManagerCost";
import { IObject } from "../../../models/models";
import {
  getSimpleCategory,
  searchDepartment,
} from "../../services/OrganizationServices";
import { TYPE } from "../../../utils/Constant";
import { getListServiceImpatientByTermId } from "../../services/ManagerServices";
import { convertNumberPrice } from "../../../utils/FunctionUtils";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
// import { OCTAutocomplete } from "@oceantech/oceantech-ui";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { localStorageItem } from "../../../utils/LocalStorage";
import OCTAutocomplete from "../../../component/input-field/OCTAutocomplete";
import { log } from "console";
import AppContext from "../../../../AppContext";
import { BedServiceDialog } from "./BedServiceDialog";
import { OctTable, columnNamesType, } from "@oceantech/oceantech-ui";

interface IProps {
  handleCloseDialog: () => void;
  bedInfo: IBed;
  handleReload: () => Promise<void>;
  bedServiceClassId: any;
}
const BedDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, bedInfo, handleReload, bedServiceClassId } = props;
  const { setPageLoading } = useContext(AppContext);
  const intl = useIntl();
  const { lang } = useMultiLanguage();
  const [listIds, setListIds] = useState("");
  const [bedServiceListData, setBedServiceListListData] = useState([]);
  const [openBedServiceDialog, setOpenBedServiceDialog] = useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    code: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    deptDepartmentId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    deptRoomId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    deptChamberId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    // parentId: Yup.string().required(
    //   intl.formatMessage({ id: "VALIDATION.REQUIRE" })
    // ),
  });
  let accessTockenDecode = localStorageItem.get(
    KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE
  );

  useEffect(() => {
    if (bedInfo?.id) {
      formik?.setValues(bedInfo);
      setListIds(bedInfo?.listTermId ? bedInfo?.listTermId : "");
    }
  }, [bedInfo]);

  useEffect(() => {
    if (listIds) {
      handleGetBedService();
    }
  }, [listIds]);

  const numberOfPatientList = [
    {
      code: 1,
      name: "Một người",
    },
    {
      code: 2,
      name: "Hai người",
    },
    {
      code: 3,
      name: "Ba người",
    },
  ];

  const handleSubmit = async (values: IBed) => {
    let _values = {
      ...values,
      // orgId: accessTockenDecode?.org,
    };
    console.log(_values);

    try {
      const res = bedInfo?.id
        ? await updateBed(_values)
        : await addNewBed(_values);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(
          bedInfo.id
            ? intl.formatMessage({ id: "TOAST.EDIT.SUCCESS" })
            : intl.formatMessage({ id: "TOAST.CREATE.SUCCESS" })
        );
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
    }
  };

  const formik = useFormik({
    initialValues: bedInfo?.id ? bedInfo : INIT_BED,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeSelect = (name: string, data: any) => {
    formik.setFieldValue(name, data);
  };

  const handleGetBedService = async () => {
    try {
      setPageLoading(true);
      const listTermId = listIds || "";
      const res = await getListServiceImpatientByTermId(listTermId);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        var list = data?.map((item: any, index: number) => {
          return {
            ...item,
            stt: index + 1,
            ...item?.attributes,
            svcFeeIns: convertNumberPrice(item?.attributes?.svcFeeIns) || 0,
            svcFeeHospital: convertNumberPrice(item?.attributes?.svcFeeHospital) || 0,
            svcFeeRequest: convertNumberPrice(item?.attributes?.svcFeeRequest) || 0,
          };
        });
        setBedServiceListListData(list);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenBedServiceDialog = () => {
    setOpenBedServiceDialog(true);
  };

  const handleCloseBedDialog = (): void => {
    setOpenBedServiceDialog(false);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("BED_SERVICE.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "60px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED_SERVICE.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "170px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("BED_SERVICE.UNIT"),
      field: "unit",
      headerStyle: {
        minWidth: "60px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_INS"),
      field: "svcFeeIns",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_HOSPITAL"),
      field: "svcFeeHospital",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang("BED_SERVICE.SVC_FEE_REQUEST"),
      field: "svcFeeRequest",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="xl" centered>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({
                  id: !bedInfo?.id ? "BED.ADD" : "BED.EDIT",
                })}
                <i
                  className={`bi ${bedInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                    } text-primary ps-2 fs-5`}
                ></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="px-6">
                <Col xs={4}>
                  <Row>
                    <Col xs={12} className={"pt-4"}>
                      <OCTAutocomplete
                        lable={lang("BED.FACULTY")}
                        options={[]}
                        value={
                          formik?.values?.deptDepartmentId ||
                          bedInfo?.deptDepartmentId ||
                          null
                        }
                        name="deptDepartmentId"
                        valueSearch="id"
                        isRequired
                        onChange={(selectedOption: any) => {
                          handleChangeSelect(
                            "deptDepartmentId",
                            selectedOption?.id
                          );
                          handleChangeSelect(
                            "deptDepartmentName",
                            selectedOption?.name
                          );
                        }}
                        getOptionValue={(option) => option.deptDepartmentId}
                        searchFunction={searchDepartment}
                        searchObject={{
                          pageSize: 999,
                          pageIndex: 1,
                          level: LEVEL_DEPARTMENT.KHOA,
                        }}
                        errors={formik.errors.deptDepartmentId}
                        touched={formik.touched.deptDepartmentId}
                      // renderChilden="subs"
                      // disabled={isView}
                      />
                    </Col>
                    <Col xs={12} className={"pt-4"}>
                      <OCTAutocomplete
                        lable={lang("BED.ROOM")}
                        options={[]}
                        value={
                          formik?.values?.deptRoomId ||
                          bedInfo?.deptRoomId ||
                          null
                        }
                        name="deptRoomId"
                        valueSearch="id"
                        isRequired
                        onChange={(selectedOption: any) => {
                          handleChangeSelect("deptRoomId", selectedOption?.id);
                          handleChangeSelect(
                            "deptRoomName",
                            selectedOption?.name
                          );
                        }}
                        isDisabled={!formik?.values?.deptDepartmentId}
                        getOptionValue={(option) => option.deptRoomId}
                        searchFunction={searchDepartment}
                        searchObject={{
                          pageSize: 999,
                          pageIndex: 1,
                          // type: formik?.values?.type,
                          level: LEVEL_DEPARTMENT.PHONG,
                          parentId: formik?.values?.deptDepartmentId,
                        }}
                        errors={formik.errors.deptRoomId}
                        touched={formik.touched.deptRoomId}
                      />
                    </Col>
                    <Col xs={12} className={"pt-4"}>
                      <OCTAutocomplete
                        lable={lang("BED.CHAMBER")}
                        options={[]}
                        value={
                          formik?.values?.deptChamberId ||
                          bedInfo?.deptChamberId ||
                          null
                        }
                        name="deptChamberId"
                        valueSearch="id"
                        isRequired
                        onChange={(selectedOption: any) => {
                          handleChangeSelect(
                            "deptChamberId",
                            selectedOption?.id
                          );
                          handleChangeSelect(
                            "deptChamberName",
                            selectedOption?.name
                          );
                        }}
                        isDisabled={!formik?.values?.deptRoomId}
                        getOptionValue={(option) => option.deptChamberId}
                        searchFunction={searchDepartment}
                        searchObject={{
                          pageSize: 999,
                          pageIndex: 1,
                          // type: formik?.values?.type,
                          level: LEVEL_DEPARTMENT.BUONG,
                          parentId: formik?.values?.deptRoomId,
                        }}
                        errors={formik.errors.deptChamberId}
                        touched={formik.touched.deptChamberId}
                      />
                    </Col>
                    <Col xs={12} className={"pt-4"}>
                      <OCTTextValidator
                        name="codeIns"
                        lable={lang("BED.CODE_INS")}
                        type={"text"}
                        onChange={formik.handleChange}
                        value={formik.values.codeIns || ""}
                        errors={formik.errors.codeIns}
                        touched={formik.touched.codeIns}
                      />
                    </Col>
                    <Col xs={12} className={"pt-4"}>
                      <OCTTextValidator
                        name="code"
                        lable={lang("BED.CODE")}
                        type={"text"}
                        isRequired
                        onChange={formik.handleChange}
                        value={formik.values.code || ""}
                        errors={formik.errors.code}
                        touched={formik.touched.code}
                      />
                    </Col>
                    <Col xs={12} className={"pt-4"}>
                      <OCTTextValidator
                        name="name"
                        lable={lang("BED.NAME")}
                        type={"text"}
                        isRequired
                        onChange={formik.handleChange}
                        value={formik.values.name || ""}
                        errors={formik.errors.name}
                        touched={formik.touched.name}
                      />
                    </Col>
                    <Col xs={6} className={"pt-4"}>
                      <OCTAutocomplete
                        lable={lang("BED.TYPE")}
                        options={[]}
                        value={
                          formik?.values?.type || bedInfo?.type
                            ? (
                              formik?.values?.type || bedInfo?.type
                            )?.toString()
                            : null
                        }
                        name="type"
                        valueSearch="code"
                        onChange={(selectedOption: any) => {
                          handleChangeSelect("type", selectedOption?.code);
                          handleChangeSelect("typeName", selectedOption?.name);
                        }}
                        searchFunction={getSimpleCategory}
                        searchObject={{
                          pageSize: 999,
                          pageIndex: 1,
                          type: SIMPLE_CATEGORY_TYPE.BED_TYPE,
                        }}
                        errors={formik.errors.type}
                        touched={formik.touched.type}
                      // renderChilden="subs"
                      // disabled={isView}
                      />
                    </Col>
                    <Col xs={6} className={"pt-4"}>
                      <OCTAutocomplete
                        lable={lang("BED.NUMBER_OF_PATIENT")}
                        options={numberOfPatientList}
                        value={
                          formik?.values?.numberOfPatient ||
                          bedInfo?.numberOfPatient ||
                          0
                        }
                        name="numberOfPatient"
                        valueSearch="code"
                        onChange={(selectedOption: any) => {
                          handleChangeSelect(
                            "numberOfPatient",
                            selectedOption?.code
                          );
                        }}
                        getOptionValue={(option) => option.numberOfPatient}
                        errors={formik.errors.numberOfPatient}
                        touched={formik.touched.numberOfPatient}
                      // renderChilden="subs"
                      // disabled={isView}
                      />
                    </Col>
                    <Col xs={6} className={"pt-4"}>
                      <CheckboxField
                        name="isOutpatient"
                        label={lang("BED.IS_OUTPATIENT")}
                        handleChange={formik.handleChange}
                        value={formik.values?.isOutpatient || false}
                      />
                    </Col>
                    <Col xs={6} className={"pt-4"}>
                      <CheckboxField
                        name="disabled"
                        label={lang("BED.DISABLED")}
                        handleChange={formik.handleChange}
                        value={formik.values.disabled || false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Col xs={3} className={"pt-4"}>
                    <OCTTextValidator
                      name="sortWeight"
                      lable={lang("BED.SORT_WEIGHT")}
                      type={"number"}
                      onChange={formik.handleChange}
                      value={formik.values.sortWeight || 0}
                      errors={formik.errors.sortWeight}
                      touched={formik.touched.sortWeight}
                    />
                  </Col>
                  <Col xs={12} className={"pt-4"}>
                    <OctTable
                      // title={lang("BED_SERVICE.LIST")}
                      isActionTableTab
                      data={bedServiceListData}
                      columns={columns}
                      noToolbar={true}
                      scrollable={true}
                      handleDeleteList={(ids: string[]) => {
                        var list = bedServiceListData
                          .filter((item: any) => !ids.includes(item.id));
                        var listTerm = list?.map((item: any) => `${item.id}`).join(',');
                        setBedServiceListListData(list);
                        setListIds(listTerm);
                        handleChangeSelect("listTermId", listTerm);
                        return true;
                      }}
                      height={400}
                      noPagination={true}
                      handleSearch={handleGetBedService}
                      unSelectedAll
                      type={TYPE.MULTILINE}
                      className="custom-list-data"
                    />
                  </Col>
                </Col>
                <Col xs={12} className={"pt-4"}>
                  <OCTTextValidator
                    name="remark"
                    lable={lang("BED.REMARK")}
                    type={"text"}
                    as={"textarea"}
                    onChange={formik.handleChange}
                    value={formik.values.remark || ""}
                    errors={formik.errors.remark}
                    touched={formik.touched.remark}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={handleCloseDialog}
              >
                {intl.formatMessage({
                  id: "BTN.CANCEL",
                })}
              </Button>
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={handleOpenBedServiceDialog}
              >
                {intl.formatMessage({
                  id: "BTN.ADD_SERVICE",
                })}
              </Button>
              <Button className="btn btn-primary btn-sm" type="submit">
                {intl.formatMessage({ id: "BTN.SAVE" })}
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
        {openBedServiceDialog && (
          <BedServiceDialog
            handleCloseDialog={handleCloseBedDialog}
            handleSelected={(data: any) => {
              setListIds(data?.listTermId);
              handleChangeSelect("listTermId", data?.listTermId);
            }}
            classId={bedServiceClassId}
            listTermId={formik?.values?.listTermId}
            bedServiceListData={bedServiceListData}
          />
        )}
      </Modal>
    </>
  );
};
export { BedDialog };
