import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  generateForm,
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
import AppContext from "../../../../AppContext";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import * as Yup from "yup";
import FormPackage from "./Forms/FormPackage";
import {
  addNewPackage,
  addServicesToPackage,
  getServicesInPackage,
  updatePackage,
} from "../../services/ManagerPackage";
type Props = {
  handleCloseDialog: () => void;
  isView?: boolean;
  packageInfo: any;
};

const initialValuesPackage: any = {
  code: "",
  name: "",
};

const PackageDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { handleCloseDialog, isView = false, packageInfo } = props;
  const { setPageLoading } = useContext(AppContext);
  const [dataGenerate, setDataGenerate] = useState();
  const [selectedPackage, setSelectedPackage] = useState<any>(null);

  const formikRef = useRef<any>(null);

  let validationSchema = Yup.object({
    name: Yup.string().required("Bắt buộc nhập").nullable(),
    code: Yup.string().required("Bắt buộc nhập").nullable(),
  });

  const convertSubmitData = (values: any) => {
    return {
      dataPackage: {
        id: values?.id,
        code: values?.code,
        name: values?.name,
        deptLaboratoryCode: values?.deptLaboratory?.code,
        deptLaboratoryName: values?.deptLaboratory?.name,
        needPaidOrderSetMember: values?.needPaidOrderSetMember,
        svcPrice: values?.svcPrice,
        svcFeeIns: values?.svcPrice,
        svcFeeHospital: values?.svcPrice,
        svcFee: values?.svcPrice,
      },
      dataServices:
        values?.orders?.map((item: any, index: any) => {
          return {
            termId: item?.id,
            feePackage: +item?.feePackage || 0,
            sequenceNumber: index + 1,
          };
        }) || [],
    };
  };

  const handleFormSubmit = async (values: any) => {
    const { dataPackage, dataServices } = convertSubmitData(values);
    setPageLoading(true);
    try {
      const res = values?.id
        ? await updatePackage(values?.id, dataPackage)
        : await addNewPackage(dataPackage);
      if (isSuccessfulResponse(res?.data?.code)) {
        const resAddServices = await addServicesToPackage(
          res?.data?.data?.id,
          dataServices
        );
        if (isSuccessfulResponse(resAddServices?.data?.code)) {
          toast.success(
            packageInfo.id
              ? lang("TOAST.EDIT.SUCCESS")
              : lang("TOAST.CREATE.SUCCESS")
          );
          handleCloseDialog();
        } else {
          handleThrowResponseMessage(res);
        }
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleGetServicesInPackage = async (packageInfo: any) => {
    setPageLoading(true);
    try {
      const { data } = await getServicesInPackage(packageInfo?.id);
      if (isSuccessfulResponse(data?.code)) {
        formikRef?.current?.setFieldValue("orders", data?.data);
      } else {
        toast.warning(data?.data?.[0].message)
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const getDataPByPackageId = (userId: string, packageInfo: any) => {
    handleGetServicesInPackage(packageInfo);
    formikRef?.current?.setValues({ ...packageInfo });
  };

  useEffect(() => {
    if (packageInfo?.id) {
      getDataPByPackageId(packageInfo?.id, packageInfo);
    }
  }, [packageInfo]);

  return (
      <Modal
        className="modal-package"
        show={true}
        onHide={handleCloseDialog}
        size="lg"
      >
        <Formik<any>
          initialValues={{ ...initialValuesPackage }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
            touched,
            resetForm,
          }) => {
            formikRef.current = {
              values: values,
              setFieldValue: setFieldValue,
              setValues: setValues,
            };

            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Cập nhật gói khám</Modal.Title>
                </Modal.Header>
                <Modal.Body className="spaces p-6">
                  <FormPackage
                    selectedPackage={selectedPackage}
                    setSelectedPackage={setSelectedPackage}
                    isView={isView}
                  />
                </Modal.Body>
                <Modal.Footer className="">
                  <div className="flex bg-white spaces border-top justify-content-end p-0">
                    {!isView && (
                      <Button
                        className="btn-fill spaces min-w-86"
                        onClick={() => handleSubmit()}
                      >
                        <span>Lưu lại</span>
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
  );
};

export default PackageDialog;
