import { FC, useContext, useEffect, useState } from "react";
import { IDepartment } from "../model/ManagerModel";
import { Row as RowTable } from "react-table";
import { toast } from "react-toastify";
import {
  deleteDepartment,
  getListDepartment,
} from "../services/ManagerServices";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import {
  RESPONSE_STATUS_CODE,
  SEARCH_OBJECT_MAX_SIZE,
  TYPE,
} from "../../utils/Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { FacultyDialog } from "./Dialog/FacultyDialog";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../../AppContext";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import { getListOrganizations } from "../services/OrganizationServices";
import { Org } from "../model/UserModels";
import { OctTable, columnNamesType, TreeView } from "@oceantech/oceantech-ui";

const FacultyList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [facultyList, setFacultyList] = useState<IDepartment[]>([]);
  const [facultyInfo, setFacultyInfo] = useState<IDepartment>(
    {} as IDepartment
  );
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [openFalcutyDialog, setOpenFacultyDialog] =
    useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>({
    ...INIT_SEARCH_OBJECT,
    orgId: "",
    level: 1,
  });
  const [orgListData, setOrgListData] = useState<Org[]>([]);
  const [orgId, setOrgId] = useState<string>();

  useEffect(() => {
    loadListDepartment();
  }, [searchObject]);

  useEffect(() => {
    handleGetListOrganization();
    loadListDepartment();
  }, []);

  const handleOpenDepartmentDialog = (data: RowTable<IDepartment>) => {
    setOpenFacultyDialog(true);
    setFacultyInfo(data);
  };

  const handleCloseDepartmentDialog = (): void => {
    setOpenFacultyDialog(false);
    setFacultyInfo({} as IDepartment);
  };

  const loadListDepartment = async () => {
    try {
      setPageLoading(true);
      const res = await getListDepartment(searchObject);
      const { data } = res?.data || {};
      let pageSize = searchObject?.pageSize;
      let totalElements = data?.pageable?.totalElements || 0;
      let totalPages =
        totalElements % pageSize === 0
          ? totalElements / pageSize
          : Math.floor(totalElements / pageSize) + 1;
      setFacultyList(data?.content);
      setTotalPage(totalPages);
      setTotalElements(totalElements);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const handleGetListOrganization = async () => {
    try {
      setPageLoading(true);
      const res = await getListOrganizations(SEARCH_OBJECT_MAX_SIZE);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgListData(data?.content);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteDepartment = async (ids: string[]) => {
    try {
      const res = await deleteDepartment(ids.toString());
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListDepartment();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleNodeClick = (node: any) => {
    let orgId = node?.id;
    setOrgId(undefined);
    if (!orgId) return;
    setOrgId(orgId);
    setSearchObject({
      ...searchObject,
      orgId: orgId,
      ...INIT_SEARCH_OBJECT,
    });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("FACULTY.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("FACULTY.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  return (
    <Row className="pt-4">
      <Col xs={4}>
        <TreeView
          title="Danh sách đơn vị"
          data={orgListData}
          onNodeClick={(node: any) => handleNodeClick(node)}
          childrenKey="subs"
        />
      </Col>
      <Col xs={8}>
        <OctTable
          title={lang("FACULTY.LIST")}
          isActionTableTab
          data={facultyList}
          columns={columns}
          handleDeleteList={(ids: string[]) => handleDeleteDepartment(ids)}
          noToolbar={true}
          buttonAdd
          handleOpenDialog={() => {
            setOpenFacultyDialog(true);
          }}
          handleDoubleClick={(row: any) => handleOpenDepartmentDialog(row)}
          isSearchable
          handleSearch={loadListDepartment}
          totalElements={totalElements}
          totalPages={totalPage}
          unSelectedAll
          type={TYPE.MULTILINE}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          className="custom-list-data"
        />
      </Col>
      {openFalcutyDialog && (
        <FacultyDialog
          handleCloseDialog={handleCloseDepartmentDialog}
          departmentInfo={facultyInfo}
          handleReload={loadListDepartment}
        />
      )}
    </Row>
  );
};
export { FacultyList };
