import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../../../AppContext";
import { TERM_CLASS_IDS } from "../../../../constant";
import {
  RESPONSE_STATUS_CODE,
  SELECTION_MODE,
} from "../../../../utils/Constant";
import { useFormikContext } from "formik";
import SelectTree from "../../SelectTree";
import TextField from "../../TextField";
import {
  CODE_DOI_TUONG,
  LOAI_PHONG,
  TreeChiDinhDichVu,
} from "../../const/ManagerCost";
import {
  DsDichVuChiDinhColumn,
  dsDichVuColumnsSimple,
} from "../../column/PackageColumn";
import { convertNumberPrice } from "../../../../utils/FunctionUtils";
import { Col, FormCheck, Row } from "react-bootstrap";
import { IconMenu } from "../../../../component/IconSvg";
import { toast } from "react-toastify";
import { getTermClasses } from "../../../services/ManagerPackage";
import SimpleTable from "../../../../component/table/simple-table/SimpleTable";
import { formatMoney, handleSearchByFuse } from "../../../../../AppFunction";
import { useDebounce } from "../../../../../../_metronic/helpers";
import InputSearch from "../../../../component/input-field/InputSearchV2";
import NumberField from "../../number-field";
import { TableCustom } from "../../../../component/table/table-custom/TableCustom";
import ConfirmDialog from "../../../../component/dialog/OCTConfirmDialog";
import { cloneDeep } from "lodash";
import AutocompleteObjectV2 from "../../../../component/AutocompleteObjectV2";
import { getListDepartment } from "../../../services/ManagerServices";
import Fuse from 'fuse.js';

type Props = {
  selectedPackage: any;
  setSelectedPackage: (listItem: any) => void;
  isView?: boolean;
};

const FormPackage = ({
  isView,
}: Props) => {
  const { setPageLoading } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext<any>();

  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
  const [listDichVu, setListDichVu] = useState<any[]>([]);
  const [listDichVuSearch, setListDichVuSearch] = useState<any[]>([]);
  const [listResponseService, setListResponseService] = useState<any>([]);
  const [textSearch, setTextSearch] = useState<string>("");
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedCol, setSelectedCol] = useState<any>({});
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] =
  useState<boolean>(false);
  const [listDepartment, setListDepartment] = useState<any>([]);
  
  const cacheTermsRef = useRef<Map<string, any>>(new Map());

  const { BAO_HIEM, VIEN_PHI, YEU_CAU } = CODE_DOI_TUONG;
  const textSearchDebounce = useDebounce(textSearch.trim(), 300);

  useEffect(() => {
    handleGetListDepartment();
    updatePageData();

    const handleClick = (event: any) => {
      if (event.target.tagName === "INPUT") {
        setSelectedRowIndex?.(null);
        setSelectedCol?.(null);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    handleSearchByFuse(
      listDichVuSearch,
      textSearchDebounce ?? "",
      setListDichVu
    );
  }, [textSearchDebounce]);

  const handleGetListDepartment = async () => {
    setPageLoading(true);
    try {
      let { data } = await getListDepartment({
        pageIndex: 1,
        pageSize: 99999,
        type: LOAI_PHONG.PHONG_KHAM_BENH,
      });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListDepartment(data?.data?.content);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.terms &&
          item?.terms?.length && {
            filter: handleConvertTreeData(item?.terms || []),
          }),
      };
    });
    return convertedData;
  };

  const getListDichVu = async (termClassId?: string) => {
    try {
      const CLASS_IDS_DICH_VU = [
        TERM_CLASS_IDS.XET_NGHIEM,
        TERM_CLASS_IDS.CDHA,
        TERM_CLASS_IDS.PTTT,
        TERM_CLASS_IDS.KHAM_BENH,
      ].join(",");

      const { data } = await getTermClasses({
        classIds: termClassId ?? CLASS_IDS_DICH_VU,
      });

      if (RESPONSE_STATUS_CODE.SUCCESS === data?.code) {
        return data.data;
      }

      return [];
    } catch (error) {
      console.error(error);
      toast.error("Xảy ra lỗi khi lấy danh sách dịch vụ, vui lòng thử lại!");
    }
  };

  const updatePageData = async () => {
    try {
      const listDichVuChiDinh = await getListDichVu();
      let treeDataConverted = {
        code: "all",
        name: "Tất cả dịch vụ",
        icon: <IconMenu />,
        filter: handleConvertTreeData(listDichVuChiDinh),
      };
      setTreeData(treeDataConverted);
      let listDichVuFull = listDichVuChiDinh.flatMap((items: any) =>
        items?.terms.flatMap((item: any) => item?.subs)
      );

      setListDichVu(listDichVuFull);
      setListResponseService(listDichVuChiDinh);
      setListDichVuSearch(
        listDichVuChiDinh.flatMap((items: any) =>
          items?.terms.flatMap((item: any) => item?.subs)
        )
      );
      cacheTermsRef.current = new Map(listDichVuFull.map((item: any) => [item?.code, item]));
    } catch (e) {
      console.error(e);
    }
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value ? `'${value}` : "";
    setTextSearch(newValue);
  };

  const handleGetSelectedItem = (data: any) => {
    if (data) {
      setListDichVu(data?.subs || []);
      setListDichVuSearch(data?.subs || []);
      setTextSearch("");
    } else {
      setListDichVu(
        listResponseService.flatMap((items: any) =>
          items?.terms.flatMap((item: any) => item?.subs)
        )
      );
      setListDichVuSearch(
        listResponseService.flatMap((items: any) =>
          items?.terms.flatMap((item: any) => item?.subs)
        )
      );
    }
  };

  const handleSelectService = (data: any) => {
    let result =
      data?.map((item: any) => ({
        ...item,
        feePackage: item?.feePackage || item?.attributes?.svcFee,
      })) || [];

    setFieldValue("orders", result);
  };

  const handleRenderPrice = (rowData: any, typeObjectCode: string) => {
    switch (typeObjectCode) {
      case YEU_CAU: {
        return rowData?.attributes?.svcFee || 0;
      }
      case VIEN_PHI: {
        return rowData?.attributes?.svcFeeHospital || 0;
      }
      case BAO_HIEM: {
        return rowData?.attributes?.svcFeeIns || 0;
      }
      default: {
        return 0;
      }
    }
  };

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    rowData = {
      ...rowData,
      svcFee: formatMoney(rowData?.attributes?.svcFee) || 0,
      svcFeeHospital: formatMoney(rowData?.attributes?.svcFeeHospital) || 0,
      svcFeeIns: formatMoney(rowData?.attributes?.svcFeeIns) || 0,
      svcQuantity: rowData?.attributes?.svcQuantity || 1,
      svcPrice: formatMoney(rowData?.attributes?.svcPrice || rowData?.attributes?.svcFee) || 0,
      deptLaboratoryName: rowData?.attributes?.deptLaboratoryName,
    };
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const handleOnRowClickCheckbox = (rowData: any) => {
    if (!rowData) return;

    let _listData = [...listDichVu];
    _listData[rowData.index].isChecked = true;
    let updatedSelectedRows = [...(values?.orders || [])];
    const selectedItem = _listData[rowData.index];

    if (!rowData?.rowData?.isChecked) {
      updatedSelectedRows.push(selectedItem);
    } else {
      updatedSelectedRows = updatedSelectedRows.filter(
        (item: any) => item?.["code"] !== selectedItem?.["code"]
      );
    }

    let result =
      updatedSelectedRows?.map((item: any) => ({
        ...item,
        feePackage: item?.feePackage || item?.attributes?.svcFee,
      })) || [];

    setFieldValue("orders", result);
    setListDichVu([..._listData]);
  };

  const handleCheck = (e: any) => {
    const isChecked = e?.target?.checked;

    if (!isChecked) {
      setFieldValue("svcPrice", "");
      setFieldValue("svcFee", "");
      setFieldValue("svcFeeHospital", "");
      setFieldValue("svcFeeIns", "");
    }
    
    setFieldValue("needPaidOrderSetMember", isChecked);
    setFieldValue("orders", values?.orders?.map((record: any) => {
      const term = cacheTermsRef.current.get(record.code);
      
      return {
        ...record,
        feePackage: isChecked ? 0 : Number(term?.attributes?.svcFee),
      }
    }));
  };

  const handleDelete = () => {
    setShouldOpenConfirmDialog(true);
  };

  const handleCaculateFee = (data: any) => {};

  const handleConfirmDelete = () => {
    let newArr = [...(values?.orders || [])];
    if (selectedRowIndex || selectedRowIndex === 0) {
      newArr.splice(selectedRowIndex, 1);
    }
    setFieldValue("orders", newArr);
    const objFee = handleCaculateFee(newArr);
    setShouldOpenConfirmDialog(false);
  };

  const updateColumnDSDichVuChiDinh = (field: string, newValue: any) => {
    let newArr: any[] = cloneDeep(values?.orders);
    newArr[selectedRowIndex as number][field] = newValue;
    setFieldValue("orders", newArr);
  };

  return (
    <div className="d-flex h-100">
      <div className="spaces width-20 bg-white radius-2 d-flex flex-column">
        <div className="spaces px-12 py-5 h-42">
          <InputSearch
            placeholder="Tìm kiếm"
            handleChange={() => {}}
            className="spaces h-32 pr-4 radius-3"
          />
        </div>
        <SelectTree
          className="w-100 py-2 ps-4 flex-grow-1 border border-top-0 overflow-auto"
          codeCollapses={codeCollapses}
          handleChangeCollapsesCode={setCodeCollapses}
          idSelected={idSelected}
          handleChangeSelectId={setIdSelected}
          getSelectedItem={handleGetSelectedItem}
          selectTree={treeData}
        />
      </div>
      <div className="d-flex flex-column spaces width-80 bg-light radius-2">
        <Row className="spaces px-5">
          <Col xs={4}>
            <TextField
              label={
                <div>
                  Mã gói khám
                  <span className="text-danger"> (*)</span>
                </div>
              }
              name="code"
              type="text"
              value={values?.code || ""}
              labelClassName="ms-0 min-w-85px"
              disabled={isView}
            />
          </Col>
          <Col xs={4}>
            <TextField
              label={
                <div>
                  Tên gói khám
                  <span className="text-danger"> (*)</span>
                </div>
              }
              name="name"
              type="text"
              value={values?.name || ""}
              labelClassName="ms-0 min-w-85px"
              disabled={isView}
            />
          </Col>
          <Col xs={4}>
            <AutocompleteObjectV2
              options={listDepartment}
              name="deptLaboratory"
              label="Phòng thực hiện"
              labelClassName="ms-0 min-w-85px spaces"
              isSearchDefauilt={true}
              onChange={(value: any) => setFieldValue("deptLaboratory", value)}
              value={values?.deptLaboratory || ""}
              isDisabled={isView}
            />
          </Col>
          <Col xs={4}>
            <FormCheck
              type="checkbox"
              label="Thanh toán theo giá gói"
              className="spaces mt-20 form-check check-box-vp flex-1 "
              checked={values?.needPaidOrderSetMember}
              disabled={isView}
              onChange={(e: any) => handleCheck(e)}
            />
          </Col>
          <Col xs={4}>
            <NumberField
              label="Đơn giá"
              labelClassName="ms-0 min-w-85px spaces"
              className="input text-end"
              name="svcPrice"
              value={values?.svcPrice || ""}
              disabled={isView || !values?.needPaidOrderSetMember}
              onChange={(value: number) => {
                setFieldValue("svcPrice", value);
              }}
            />
          </Col>
        </Row>
        <div className="spaces flex-grow-1 bg-white px-0 radius-2 mt-6 table-ke-thuoc">
          <div className="d-flex flex-column spaces width-100 bg-light">
            <div className="bg-white">
              <div className="px-10 py-5 h-35 spaces">
                <InputSearch
                  placeholder="Tìm kiếm"
                  handleChange={handleChange}
                  className="spaces h-25 pr-4 radius-3"
                />
              </div>
              <div className="spaces h-180 bg-white mb-6">
                <SimpleTable
                  data={listDichVu || []}
                  columns={dsDichVuColumnsSimple}
                  height={180}
                  width={1000}
                  scrollable={true}
                  type={SELECTION_MODE.MULTI_TABLE}
                  setDataChecked={handleSelectService}
                  dataChecked={values?.orders || []}
                  handleRenderCell={handleRenderCell}
                  onRowClick={(rowData: any) => {
                    handleOnRowClickCheckbox(rowData);
                  }}
                  keySearch={textSearchDebounce}
                  isDisabledCheckbox={isView}
                />
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
              <div className="d-flex spaces pt-10 pb-6 px-10 text-pri fw-bold">
                Phiếu chỉ định
              </div>
              <div className="flex-grow-1 spaces table-pcd">
                <TableCustom
                  columns={DsDichVuChiDinhColumn(
                    handleRenderPrice,
                    selectedRowIndex,
                    setSelectedRowIndex,
                    selectedCol,
                    setSelectedCol,
                    [],
                    updateColumnDSDichVuChiDinh,
                    handleDelete,
                    isView
                  )}
                  data={values?.orders || []}
                  className="spaces h-calc-vh-593"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between spaces px-10 mt-6 bg-white">
              <div className={`price-info spaces my-5 ml-10`}>
                <div className="text-danger fw-bold">
                  Tổng chi phí:{" "}
                  {values?.needPaidOrderSetMember
                    ? formatMoney(+values?.svcPrice)
                    : convertNumberPrice(
                        values?.orders?.reduce(
                          (acc: number, item: { feePackage: number }) =>
                            acc + (+item?.feePackage || 0),
                          0
                        )
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {shouldOpenConfirmDialog &&
          <ConfirmDialog
            show={shouldOpenConfirmDialog}
            title="Thông báo"
            message="Bạn có chắc chắn muốn bỏ dịch vụ này không ?"
            yes="Xác nhận"
            close="Đóng"
            onCloseClick={() => setShouldOpenConfirmDialog(false)}
            onYesClick={() => handleConfirmDelete()}
          />
        }
      </div>
    </div>
  );
};

export default FormPackage;
